import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderHero from "@components/homepage/HeaderHero"
import ImproveYourJourney from "@components/homepage/ImproveYourJourney"
import FeaturesMarquee from "@components/homepage/FeaturesMarquee"
import GrowYourWealth from "@components/homepage/GrowYourWealth"
import LearnTopics from "@components/homepage/LearnTopics"
import SomePartners from "@components/homepage/Partners"
import JAPartner from "@components/homepage/JaPartner"
import { TokenStatusProvider } from "@context/TokenStatusContext"
import CourseTopics from "@components/homepage/CourseTopics"
import GetStartedNow from "@components/homepage/GetStartedNow"
import DeskImg from "@components/homepage/DeskImg"
import EstimateGains from "@components/homepage/EstimateGains"
import CustomerQuotesTitle from "@components/homepage/CustomerQuotesTitle"
import CustomerQuotes from "@components/homepage/CustomerQuotes"

interface IComponentState {
  pledge: number
  rangeVisibility: number
  displayValue: number
  checkError: string
  tokenPresent: boolean
}
interface IComponentProps {}
class MainLandingPage extends React.Component<
  IComponentProps,
  IComponentState
> {
  constructor(props) {
    super(props)
    this.state = {
      pledge: 200,
      rangeVisibility: 0,
      displayValue: 100,
      checkError: "no-error",
      tokenPresent: false
    }
  }

  render() {
    const { landing } = data
    return (
      <TokenStatusProvider>
        <Layout
          type="light"
          pageClass="marketing"
          tokenPresent={this.state.tokenPresent}
          partner="JA"
        >
          <SEO title={landing.title} />
          <HeaderHero tokenPresent={this.state.tokenPresent} />
          <ImproveYourJourney />
          <DeskImg />
          <LearnTopics />
          <FeaturesMarquee />
          <GrowYourWealth />
          <CourseTopics />
          <EstimateGains />
          <CustomerQuotesTitle />
          <CustomerQuotes />
          <SomePartners />
          <JAPartner />
          <GetStartedNow tokenPresent={this.state.tokenPresent} />
        </Layout>
      </TokenStatusProvider>
    )
  }
}

export default MainLandingPage
