import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import MainLandingPage from "@components/MainLandingPage"
import LandingPageFBLA from "@components/LandingPageFBLA"

class PledgeLandingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attributionCode: null,
      partnerId: null
    }
  }
  componentDidMount = () => {
    let urlParams = new URLSearchParams(window.location.search),
      attributionCode = urlParams.get(`attribution-code`),
      partnerId = urlParams.get(`attribution-partner-id`)
    if (attributionCode) {
      this.setState({ attributionCode })
      localStorage.setItem("attributionCode", attributionCode)
    }
    if (partnerId) {
      this.setState({ partnerId })
      localStorage.setItem("partnerId", partnerId)
    }
  }
  checkForCode = () => {
    if (this.state.attributionCode) {
      if (this.state.partnerId) {
        if (this.state.partnerId === process.env.JA_PARTNER_ID) {
          return <MainLandingPage partner="JA" />
        } else if (this.state.partnerId === process.env.FBLA_PARTNER_ID) {
          {
            if (typeof window !== "undefined") {
              window.location = "/partners/fbla"
            }
          }
        } else {
          return <MainLandingPage partner="JA" />
        }
      }
    } else {
      return <MainLandingPage partner="JA" />
    }
  }
  render() {
    const { landing } = data
    return this.checkForCode()
  }
}

export default PledgeLandingPage
